import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      headerInfo: () => import('@/modules/product/components/productListHeaderInfo.vue'),
      searchFilterOrder: 0,
      pageTitle: 'module.product',
      search: 'product.search_keyword',
      liffFilter: {
        id: {
          type: 'tab',
          // create: () => {
          //   // @ts-ignore
          //   const searchKeyword = vm.searchKeyword
          //   return !searchKeyword
          // },
          options: (formMeta:any) => {
            if(!formMeta) return []
            return formMeta.productMenus
          },
        },
      },
      // selectedData: row => ({
      //   id: row.id,
      //   name: row.name,
      //   title: row.title,
      // }),
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      listItem: () => import('@/modules/product/components/productListItem.vue'),
      itemCols: {
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6,
        cols: 6,
      },
      // batch: {
      //   delete: {
      //     targetLabel: row => row.name,
      //   },
      // },
      // metaAction: {
      //   create: {
      //     label: 'action.create',
      //     linkTarget: '_self',
      //     type: 'route',
      //     route: () => ({ name: 'product-create' }),
      //   },
      // },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'product-update', params: { target: row.id } }),
          linkTarget: '_self',
          color: 'primary'
        },
        down: {
          label: 'action.down',
          create: (row) => {
            return row.status
          },
          color: 'red',
          callback: (row, listActions) => {
            //@ts-ignore
            vm.patchApi(false, row, { 'key': 'status' })
            listActions.index()
          },
        },
        up: {
          label: 'action.up',
          create: (row) => {
            return !row.status
          },
          callback: (row, listActions) => {
            //@ts-ignore
            vm.patchApi(true, row, { 'key': 'status' })
            listActions.index()
          },
        },
        delete: {
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
